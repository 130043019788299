import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
export const LivePageTemplate = ({
  title,
  subtitle,
  featuredImage,
  body,
  featuredImgGatsby
}) => {
  const [liveVideoId, setLiveVideoId] = useState('')

  useEffect(() => {
    fetch('/.netlify/functions/getCurrentLiveVideo')
      .then(res => res.json())
      .then(liveVideo => {
        setLiveVideoId(liveVideo.id)
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <main>
      <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
        featuredImgGatsby={featuredImgGatsby}
      />

      <section className="section">
        <div className="container">
          <Content source={body} />

          <div className="Content ">
            <h2>🔴 Live Stream</h2>
            <p>
              You can watch The Holy Mass, The Holy Adoration, The Holy Rosary,
              or any event at St Francis Xavier Chicalim which is being
              livestreamed in the video player below.
            </p>
            <p>
              <em>
                The live stream video player will start playing when we are
                online and live streaming.
              </em>
            </p>

            {liveVideoId && (
              <div
                className="gatsby-resp-iframe-wrapper"
                style={{
                  paddingBottom: '75%',
                  position: 'relative',
                  height: 0,
                  overflow: 'hidden'
                }}
              >
                <iframe
                  src={`https://www.youtube.com/embed/${liveVideoId}?autoplay=1&widget_referrer=sfxchicalim.org&iv_load_policy=3&modestbranding=1`}
                  title="YouTube video player"
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                  }}
                />
              </div>
            )}
            <h2>Ways To Connect</h2>
            <p>
              Follow us on Social Media. Subscribe to our{' '}
              <a
                href="https://www.youtube.com/channel/UCK3xqRijSHJP-IuxD3XNEiA?sub_confirmation=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube channel
              </a>{' '}
              and click the bell icon 🔔 on our channel page to get notified
              when we are live.
            </p>
            <h2>Watch It Again</h2>
            <p>
              Many of our previous Holy Mass's and Holy Adoration's can be
              viewed in the video players below or directly by visiting our{' '}
              <a
                href="https://www.youtube.com/c/SFXCHICALIM/"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube channel
              </a>
              .
            </p>
            <h3>Holy Mass's</h3>
            <div
              className="gatsby-resp-iframe-wrapper"
              style={{
                paddingBottom: '75%',
                position: 'relative',
                height: 0,
                overflow: 'hidden'
              }}
            >
              <iframe
                src={
                  'https://www.youtube.com/embed?listType=playlist&list=PLjxSjavQlwt5kj7K6U0-DR_r1d3Nacwdo&iv_load_policy=3&widget_referrer=sfxchicalim.org'
                }
                frameBorder={0}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
              />
            </div>
            <h3>Holy Adoration's</h3>
            <div
              className="gatsby-resp-iframe-wrapper"
              style={{
                paddingBottom: '75%',
                position: 'relative',
                height: 0,
                overflow: 'hidden'
              }}
            >
              <iframe
                src={
                  'https://www.youtube.com/embed?listType=playlist&list=PLjxSjavQlwt5JQfW9i0AZLuMNUKfNOdHD&iv_load_policy=3&widget_referrer=sfxchicalim.org'
                }
                frameBorder={0}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
              />
            </div>
            <h2>Additional Helpful Information</h2>
            <p>If you are facing any difficulties:</p>
            <ol>
              <li>Please try refreshing / reloading this page.</li>
              <li>
                Watch the livestream directly on{' '}
                <a
                  href="https://www.youtube.com/c/SFXCHICALIM/live"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>
                .
              </li>
              <li>
                Please send us a message on our{' '}
                <a href="/contact">Contact page</a> and we will try our best to
                get it working for you.
              </li>
            </ol>
          </div>
        </div>
      </section>
    </main>
  )
}

const LivePage = ({ data: { page } }) => {
  const featuredImgUrl =
    page.featuredImg && page.featuredImg.childImageSharp.fixed.src
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
      ogImageUrl={featuredImgUrl || ''}
    >
      <LivePageTemplate
        {...page.frontmatter}
        body={page.html}
        featuredImgGatsby={page.featuredImg}
      />
    </Layout>
  )
}
export default LivePage

export const pageQuery = graphql`
  query LivePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed(height: 200, width: 200) {
            src
          }
        }
      }
    }
  }
`
